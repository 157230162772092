import { Link, useHistory } from "react-router-dom";

export default function CatalogueProduct({ product }) {
  const imageUrl = process.env.REACT_APP_ENVIRONMENT === "production" ? product.image_url : "https://picsum.photos/200";

  const history = useHistory();

  const onClick = () => {
    sessionStorage.setItem("prevUrl", JSON.stringify(history.location.search));
  };

  return (
    <div className={`relative`}>
      {product.internal_notes && (
        <div className="absolute z-20 top-2 left-2">
          <div className="relative group">
            <p className="text-xs text-primary px-2 py-[2px] border-2 border-primary rounded-full font-extrabold">!</p>
            <ul
              className={`absolute z-20 hidden group-hover:block right-0 shadow-xl border border-gray-200 rounded-lg text-sm bg-[#F0F5FA] p-4 w-96 `}
            >
              {product.internal_notes
                .split("-")
                .map((item) => item.trim())
                .filter((item) => item.length > 0)
                .map((item, i) => (
                  <li className="">- {item}</li>
                ))}
            </ul>
          </div>
        </div>
      )}

      <Link to={`/products/${product.id}`} onClick={onClick}>
        <div className="rounded-xl bg-white overflow-hidden relative cursor-pointer w-full    border shadow-md">
          <div className={`${!product.active ? "bg-[#00000075] absolute inset-0 z-10 aspect-square" : ""}`}></div>
          {product.custom_design && (
            <div className="px-2 py-1 bg-primary text-white font-bold absolute top-o right-0 rounded-full mt-2 mr-2 text-xs z-20">
              Custom
            </div>
          )}
          <div className="">
            <div className="w-full aspect-square relative group">
              <img src={imageUrl} alt={product.name} className="w-full h-full object-cover aspect-square" />
              {/* {product.internal_notes && (
                <>
                  <div className="absolute group-hover:hidden top-2 right-2 font-extrabold border-2 border-primary text-primary px-2 py-[2px] rounded-full text-xs">
                    !
                  </div>
                  <div className="absolute px-3 top-0 bottom-0 right-0 left-0 text-[10px] text-white  bg-[#00000087] hidden group-hover:flex flex-col gap-2 justify-center items-center">
                    <p className="line-clamp-[9]">
                      {product.internal_notes}
                      {product.internal_notes}
                      {product.internal_notes}
                      {product.internal_notes}
                      {product.internal_notes}
                      {product.internal_notes}
                      {product.internal_notes}
                      {product.internal_notes}
                    </p>
                  </div>
                </>
              )} */}
            </div>
            <h4 className={`line-clamp-2 px-2 pt-2 h-14 mb-2 ${!product.active ? "text-gray-500" : "text-black"}`}>
              {product.name}
            </h4>
            <p
              className={`font-bold text-sm px-2 line-clamp-1 h-5 mb-3 ${
                !product.active ? "text-gray-500" : "text-primary"
              }`}
            >
              {product.price_lowest > 0 && (
                <>
                  <span className="font-medium">Rp</span>
                  {new Intl.NumberFormat("id-ID", {
                    currency: "IDR",
                    maximumFractionDigits: 0,
                  }).format(product.price_lowest)}
                  <span className="font-bold"> - </span>
                </>
              )}
              <span className="font-medium">Rp</span>
              {new Intl.NumberFormat("id-ID", {
                currency: "IDR",
                maximumFractionDigits: 0,
              }).format(product.price)}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
}
