import api from "lib/api";
import { useEffect, useState } from "react";
import Pagination from "components/Pagination";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

export default function Cataloguehampers({ renderProduct }) {
  let history = useHistory();
  let query = useLocation();
  const queryObject = queryString.parse(query.search);

  const [search, setSearch] = useState({
    name: queryObject.item ? queryObject.item : "",
  });

  const [pagination, setPagination] = useState(null);
  const [data, setData] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [inputName, setInputName] = useState(queryObject.item ? queryObject.item : "");

  useEffect(() => {
    if (pagination) {
      let obj = {
        ...queryObject,
        ...(pagination && { page: pagination.page }),
        ...(search.name.length > 0 && { item: search.name }),
      };

      pagination.page === 1 && delete obj.page;
      search.name.length === 0 && delete obj.item;

      if (obj) {
        const queryParams = new URLSearchParams(obj).toString();
        history.push(`${window.location.pathname}?${queryParams}`);
      } else {
        history.push(`${window.location.pathname}`);
      }
    }
  }, [search, pagination, history]); // eslint-disable-line react-hooks/exhaustive-deps

  let filter = async () => {
    if (loadingData) return;
    setLoadingData(true);

    let payload = {
      page: pagination ? pagination.page : queryObject.page ? queryObject.page : 1,
      limit: pagination ? pagination.limit : 20,
      name: search.name,
      active: 1,
    };

    const { data, err } = await api.controllers.getPackages(payload.page, payload.limit, payload);

    setLoadingData(false);

    if (!err) {
      setData(data);
    }
  };

  useEffect(() => {
    resetPagination();
  }, [search.name, search.gift_type]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filter();
  }, [pagination]); // eslint-disable-line react-hooks/exhaustive-deps

  let resetPagination = () => {
    setPagination({ page: 1, limit: 20 });
  };

  const onPageChange = (page, limitPerPage) => {
    setPagination({ page: page, limit: limitPerPage ? limitPerPage : 20 });
  };

  let onQueryChange = (e) => {
    setInputName(e.target.value);
  };
  let onQueryEnter = (e) => {
    if (e.key === "Enter") {
      setSearch({ ...search, name: inputName });
    }
  };
  let onUpdateName = () => {
    setSearch({ ...search, name: inputName });
  };

  return (
    <>
      <div className="relative w-full mb-4 flex">
        <div className={`relative w-full`}>
          <div className="lg:flex items-center gap-3 w-fit">
            <div className="relative flex justify-center items-center w-full mb-3 max-w-[18rem]">
              <input
                name="name"
                type="search"
                className="border-0 px-3 py-2 placeholder-slate-300 text-slate-600 bg-white rounded-l text-sm shadow w-full"
                placeholder="Search"
                value={inputName}
                onChange={onQueryChange}
                onKeyUp={onQueryEnter}
              />
              <div
                className="bg-primary text-white font-bold uppercase text px-3 py-2 rounded-r shadow cursor-pointer"
                onClick={onUpdateName}
              >
                <i className="fas fa-search" />
              </div>
            </div>
          </div>

          <div className={`w-full`}>
            {data && data.data && (
              <>
                {loadingData && <p className="text-sm text-center text-gray-400 mb-4">Loading...</p>}

                {/* filter */}
                {!loadingData && (
                  <>
                    {data.data.length > 0 && (
                      <div className={`grid grid-cols-5 gap-x-3 gap-y-4 mb-4`}>
                        {renderProduct &&
                          data.data.map((product) =>
                            renderProduct(product, {
                              reload: filter,
                              suggestionItem: false,
                            })
                          )}
                      </div>
                    )}

                    {data.data.length <= 0 && <p className={` text-center mb-4 p-8`}>No items found</p>}
                  </>
                )}
              </>
            )}

            <Pagination
              pageCount={data.total_page}
              itemCount={data.total_items}
              onPageChange={onPageChange}
              currentPage={queryObject.page}
              showAll={false}
            />
          </div>
        </div>
      </div>
    </>
  );
}
